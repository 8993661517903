// Import React Library
import React from 'react';

// Import Images
import logoPreHouse from '../assets/images/logo-pre-house.png'

// Cover component
const Cover = ({section , coverImg, coverTitle, coverSubtitle}) => {
    return (
        <section className={section + '-cover-section cover-section'}>
            {coverImg ? <img src={logoPreHouse} alt='Logo PreHouse'/> : ''}
            {coverTitle ? <h1>{coverTitle}</h1> : ''}
            {coverSubtitle ? <h2>{coverSubtitle}</h2> : ''}
        </section>
    );
};

// Export Cover component
export default Cover;