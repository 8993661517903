// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Constructions Component
const Constructions = () => {
    return (
        <>
            <Navbar />
            <Cover section='constructions' coverTitle='Construcciónes' coverSubtitle='Viviendas - Galpones - Techos - Obradores'/>
            <WhatsappButton />
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Constructions Component
export default Constructions;