// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import ProductCard from '../components/ProductCard';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';


// Import Doors Images
import panel1 from '../assets/images/isopaneles/isopanel-1.png';
import panel2 from '../assets/images/isopaneles/isopanel-2.png';
import panel3 from '../assets/images/isopaneles/isopanel-3.png';
import panel4 from '../assets/images/isopaneles/isopanel-4.png';
import panel7 from '../assets/images/isopaneles/isopanel-7.png';
import panel9 from '../assets/images/isopaneles/isopanel-9.png';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Isopaneles Component
const Isopaneles = () => {
    return (
        <>
            <Navbar />
            <Cover section='paneles' coverTitle='Isopaneles' coverSubtitle='Trapezoidal - Engrafado - Poliestireno - Poliuretano - Foil'/>
            <WhatsappButton />
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Isopaneles <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Isopanel de 10cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 95cm. Formato trapezoidal. Autoportancia 5.50mts. Calibre de chapa 26.'
                                cardPrice='USD 39.90 c/ml'
                                cardImage={panel4}
                                productSize='0.95mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 5cm'
                                cardDescription='Isopanel de 5cm de espesor Ancho util 95cm. Formato trapezoidal. Autoportancia 3.00mts. Calibre de chapa 26.'
                                cardPrice='USD 29.90 c/ml'
                                cardImage={panel4}
                                productSize='0.95mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 10cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 5.50mts. Calibre de chapa 24.'
                                cardPrice='USD 44.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 15cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 7.50mts. Calibre de chapa 24.'
                                cardPrice='USD 49.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 20cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 9.00mts. Calibre de chapa 24.'
                                cardPrice='USD 54.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 25cm'
                                cardDescription='Isopanel de 10cm de espesor Ancho util 1.135mts. Formato engrafado. Autoportancia 10.00mts. Calibre de chapa 24.'
                                cardPrice='USD 59.90 c/m2'
                                cardImage={panel9}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de Foil'
                                cardDescription='Isopanel de foil de 3cm de espesor de poliestireno. Ancho util 95cm. Calibre de chapa 26. Formato trapezoidal.'
                                cardPrice='USD 24.90 c/ml'
                                cardImage={panel3}
                                productSize='0.95mts'
                                productColor='Negro (Grafito)'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de Foil'
                                cardDescription='Isopanel de foil de 3cm de espesor de poliestireno. Ancho util 95cm. Calibre de chapa 26. Formato trapezoidal.'
                                cardPrice='USD 24.90 c/ml'
                                cardImage={panel1}
                                productSize='0.95mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 10cm'
                                cardDescription='Isopanel para pared de 10cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 44.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 15cm'
                                cardDescription='Isopanel para pared de 15cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 49.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 20cm'
                                cardDescription='Isopanel para pared de 20cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 54.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Isopanel de 25cm'
                                cardDescription='Isopanel para pared de 25cm de espesor. Calibre de la chapa 24. Ancho util 1.135mts.'
                                cardPrice='USD 59.90 c/m2'
                                cardImage={panel7}
                                productSize='1.135mts'
                                productColor='Blanco'
                            />

                            <ProductCard 
                                cardTitle='Chapa Galvanizada'
                                cardDescription='Chapa galvanizada calibre 26 - Ancho util 95cm - Formato trapezoidal.'
                                cardPrice='USD 9 c/ml'
                                cardImage={panel2}
                                productSize='0.95mts'
                                productColor='Blanco'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Isopaneles Component
export default Isopaneles;