// Import React Library
import { useState } from 'react';

// Import Bootstrap Modal
import Modal from 'react-bootstrap/Modal';

// Import Bootstrap Card
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

// Import Icons
import CategoryIcon from '@mui/icons-material/Category';
import ColorLensIcon from '@mui/icons-material/ColorLens';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import DescriptionIcon from '@mui/icons-material/Description';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// import Atropos component
import Atropos from 'atropos/react';

// Prodcut Card Component
function ProductCard({cardTitle , cardDescription, cardImage, productSize, productColor, cardPrice}) {

    // Handle Modal Component State 
    const [fullscreen, setFullscreen] = useState(true);
    const [show, setShow] = useState(false);

    function handleShow(breakpoint) {
        setFullscreen(breakpoint);
        setShow(true);
    }

  return (
    <>
      <Card style={{ width: '18rem', height: '550px', margin: '1rem' }} className='card-product col-md-3 col-sm-6 col-12 mb-3'>
        <Card.Img className='card-image' variant="top" src={cardImage} />
        <Card.Body style={{ margin: '1rem 0' }}>
          <Card.Title className='card-title'>{cardTitle}</Card.Title>
          <Card.Text>
              {cardDescription}
          </Card.Text>

          <Button style={{ margin: '1rem 0' }} key={0} className='card-button btn btn-dark' onClick={() => handleShow(true)}>
                Ver Producto
            </Button>
        </Card.Body>
      </Card>

      <Modal show={show} fullscreen={fullscreen} onHide={() => setShow(false)} className='product-modal'>
        <Modal.Header closeButton>
            <Modal.Title className='prehouse-modal-product-title'>{cardTitle}</Modal.Title>
        </Modal.Header>
        <Modal.Body className='prehouse-body-modal'>
          <Atropos 
            className="my-atropos"
            activeOffset={80}
            shadowScale={0}
          >
            <img src={cardImage} alt='Premoldeados del este' className='prehouse-modal-image'/>
          </Atropos>

          <div className='prehouse-modal-product'>
            <p className='prehouse-modal-title'>
              <CategoryIcon className='prehouse-modal-icon'/> &nbsp; {cardTitle}
            </p>
            <br/>
            <p>
              <ColorLensIcon className='prehouse-modal-icon'/> &nbsp; {productColor}
            </p>
            <br/>
            <p>
              <SquareFootIcon className='prehouse-modal-icon'/> &nbsp; {productSize}
            </p>
            <br/>
            <p>
              <DescriptionIcon className='prehouse-modal-icon'/> &nbsp; {cardDescription}
            </p>
            <br/>
            <p className='modal-product-price'>
              <LocalOfferIcon className='prehouse-modal-icon-price'/> &nbsp; {cardPrice}
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

// Export Product Card Component
export default ProductCard;