// Import React Library
import React from 'react';

// Import Dialog Components
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';

// Import Icons
import LoyaltyIcon from '@mui/icons-material/Loyalty';

// Import Images
import steelImages from '../assets/images/canos/canos-galvanizados.png'

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
  },
  '& .MuiDialogActions-root': {
    padding: theme.spacing(1),
  },
}));

// News Component
const News = () => {
    const [open, setOpen] = React.useState(true);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <React.Fragment>
            <BootstrapDialog
                onClose={handleClose}
                aria-labelledby="customized-dialog-title"
                open={open}
                className='dialog-container'
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    OFERTA 15% DE DESCUENTO EN CAÑOS  <LoyaltyIcon fontSize='large' className='sale-icon'/>
                </DialogTitle>

                <IconButton
                aria-label="close"
                onClick={handleClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers className='dialog-content'>
                    <Typography gutterBottom className='news-parragraph'>
                        Por el mes de Junio contamos con una imperdible oferta de un 15% de descuento en caños.
                    </Typography>
                    <br />
                    <Typography gutterBottom className='news-parragraph'>
                    La oferta aplica para los siguientes productos:
                    </Typography>
                    <br />
                    <Typography gutterBottom className='news-parragraph'>
                        Caños de hierro negro, caños galvanizados en frio, caños galvanizados en caliente, soleras estructurales, perfiles C y angulos estructurales.
                    </Typography>

                    <img src={steelImages} alt='Deposito de hierros'/>
                </DialogContent>
            </BootstrapDialog>
        </React.Fragment>
    );
};

// Export News Component
export default News;