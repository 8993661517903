// Import React Library
import React, { useState, useEffect } from 'react';

// Import Icons
import LocationOnIcon from '@mui/icons-material/LocationOn';
import BusinessIcon from '@mui/icons-material/Business';
import GroupIcon from '@mui/icons-material/Group';
import NewReleasesIcon from '@mui/icons-material/NewReleases';

// Import Tootltips
import Tooltip from '@mui/material/Tooltip';

// Import React Reveal
import { Fade } from "react-awesome-reveal";

// Location Component
const Location = () => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        // Function to handle complete charge.
        const handleLoad = () => {
          setIsLoading(false);
        };
    
        // Adding charge event to load.
        window.addEventListener('load', handleLoad);
    
        // Clean event.
        return () => {
          window.removeEventListener('load', handleLoad);
        };
      }, []);


    return (
        <div id='location-section' className='location'>

            <div className='location-container'>
                <h2>UBICACIÓN<LocationOnIcon fontSize='large' className='location-icon'/></h2>
                <span></span>
                <p>Nuestra ubicación estratégica facilita el acceso y salida de los productos a cualquier punto del país haciendo de la experiencia un proceso agradable y cómodo.</p>       
                <br />

                <div className='location-data'>
                    <GroupIcon fontSize='large' className='business-icon'/>
                    <h3>Ruta 8 - KM 27.100 - Parada 33</h3>
                    <p>Local comercial abierto al publico.</p>
                </div>

                <div className='location-data'>
                    <BusinessIcon fontSize='large' className='business-icon'/>
                    <h3>Centro logistico Parque Ventura</h3>
                    <p>Deposito.</p>
                </div>

                <div className='location-data'>
                    <NewReleasesIcon fontSize='large' className='business-icon'/>
                    <h3>Proximamente en ruta 101</h3>
                    <p>Local comercial abierto al publico.</p>
                </div>
            </div>

            <div className='location-maps location-container'>
                <div className='local-point'>
                    <Tooltip title="Local comercial abierto a todo el publico." arrow placement="top-start">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d209547.8504145985!2d-56.28468451252846!3d-34.851574492059505!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a0265d397087e7%3A0x554e4a1dbdfbe70c!2sPreHouse%20Uruguay!5e0!3m2!1ses!2suy!4v1718126650530!5m2!1ses!2suy" width="" height="" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe> 
                    </Tooltip>
                </div>

            
                <div className='local-point'>
                    <Tooltip title="Deposito logistico de mercaderia." arrow placement="top-end">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3277.4735282954125!2d-56.00823182425248!3d-34.76885267289551!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x95a02635284ba7d5%3A0xee943dd8741a8b2f!2sCam.%20Lorenzo%20Ventura%2C%2015500%20Barros%20Blancos%2C%20Departamento%20de%20Canelones!5e0!3m2!1ses!2suy!4v1718293273531!5m2!1ses!2suy" width="" height="" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </Tooltip>
                </div>
            </div>
        </div>
    );
};

// Export Location Component
export default Location;