// Import React Library
import React from 'react';

// Import Components
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import ProductCard from '../components/ProductCard';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Import Doors Images
import door1 from '../assets/images/doors/brown-door.png';
import door2 from '../assets/images/doors/white-door.png';

// Windows Component
const Windows = () => {
    return (
        <>
            <Navbar />
            <Cover section='windows' coverTitle='Aberturas' coverSubtitle='Ventanas - Puertas Ventana - Pueras de Madera'/>
            <WhatsappButton />
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Puertas <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Puerta de madera'
                                cardDescription='Puerta interior de madera laminada en PVC.'
                                cardPrice='USD 180'
                                cardImage={door1}
                                productSize='0.80 x 2.10'
                                productColor='Marron oscuro'
                            />

                            <ProductCard 
                                cardTitle='Puerta de madera'
                                cardDescription='Puerta interior de madera laminada en PVC.'
                                cardPrice='USD 180'
                                cardImage={door2}
                                productSize='0.80 x 2.10'
                                productColor='Blanco'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <FooterWave />
            <Footer />
        </>
    );
};

// Export Windows Component
export default Windows;