// Import React Library
import React from 'react';

// Import Icons
import InfoIcon from '@mui/icons-material/Info';

// Import Icons
import ApartmentIcon from '@mui/icons-material/Apartment';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';

// About Us component
const AboutUs = () => {
    return (
        <div className='about-us'>
            <div className='about-us-container about-us-image'></div>

            <div className='about-us-container about-us-data'>
                <h2>Sobre Nosotros <InfoIcon  className='about-icon'/></h2>
                <span></span>

                < ApartmentIcon  className='about-us-icon'/>
                <p>
                    Somos una empresa con amplia experiencia en el rubro de la importación de productos de la construcción. 
                </p>

                <FamilyRestroomIcon className='about-us-icon'/>
                <p>
                    Gracias a nuestra naturaleza de empresa familiar brindamos no solo productos con amplio respaldo sino 
                    también un servicio pre y post venta de carácter personal y amigable.
                </p>
            </div>
        </div>
    );
};

// Export About Us component
export default AboutUs;