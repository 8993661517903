// Import React Library
import React from 'react';

// Import Component
import Navbar from '../components/Navbar';
import Cover from '../components/Cover';
import Footer from '../components/Footer';
import ProductCard from '../components/ProductCard';
import WhatsappButton from '../components/WhatsappButton';
import FooterWave from '../components/FooterWave';

// Import Icons
import LocalOfferIcon from '@mui/icons-material/LocalOffer';

// Import Doors Images
import door1 from '../assets/images/doors/brown-door.png';
import door2 from '../assets/images/doors/white-door.png';

// Import Tools Images
import toolProduct1 from '../assets/images/tools/tool-product-1.png';
import toolProduct2 from '../assets/images/tools/tool-product-2.png';
import toolProduct3 from '../assets/images/tools/tool-product-3.png';
import toolProduct4 from '../assets/images/tools/tool-product-4.png';
import toolProduct5 from '../assets/images/tools/tool-product-5.png';
import toolProduct6 from '../assets/images/tools/tool-product-6.png';
import toolProduct7 from '../assets/images/tools/tool-product-7.png';
import toolProduct8 from '../assets/images/tools/tool-product-8.png';
import toolProduct9 from '../assets/images/tools/tool-product-9.png';
import toolProduct10 from '../assets/images/tools/tool-product-10.png';
import toolProduct11 from '../assets/images/tools/tool-product-11.png';

// PreHoue Home Component
const PreHouseHome = () => {
    return (
        <>
            <Navbar />
            <Cover section='home-products' coverTitle='PreHouse Home' coverSubtitle='Herramientas - Losa radiante - Selladores - Iluminaria'/>
            <div className='pre-house-home-section'>
                <div className='product-section'>
                    <div className='products-title'>
                        <h2>Herramientas <LocalOfferIcon  className='product-icon'/></h2>
                        <span></span>
                    </div>

                    <div className='container products-container'>
                        <div className='row products-row'>
                            <ProductCard 
                                cardTitle='Pistola de silicona.'
                                cardDescription='Pistola de silicona 20V - dos Amp con 2 baterias de litio.'
                                cardImage={toolProduct1}
                                cardPrice='$ 3.900'
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Sierra Caladora'
                                cardDescription='Sierra Caladora - 20v - dos baterias de litio.'
                                cardPrice='$ 6.370'
                                cardImage={toolProduct2}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Llave de impacto'
                                cardDescription='Llave de impacto - 20v - 4Amp - 350Nm - 2 en 1.'
                                cardPrice='$ 7.150'
                                cardImage={toolProduct3}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Foco 20v - 4Amp'
                                cardDescription='Foco 20v - 4Amp - dos baterias de litio.'
                                cardPrice='$ 4.680'
                                cardImage={toolProduct4}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Mini sierra'
                                cardDescription='Mini sierra - 20v - Una bateria de litio.'
                                cardPrice='$ 4.940'
                                cardImage={toolProduct5}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Rotomartillo'
                                cardDescription='Rotomartillo - 20v - 4Amp - 3J con puntas y mechas.'
                                cardPrice='$ 9.100'
                                cardImage={toolProduct6}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Amoladora'
                                cardDescription='Amoladora - 20V - 4Amp - dos baterias de litio.'
                                cardPrice='$ 8.840'
                                cardImage={toolProduct7}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Nivel Laser'
                                cardDescription='Nivel laser 5 lineas (Verdes).'
                                cardPrice='$ 3.770'
                                cardImage={toolProduct8}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Taladro Atornillador'
                                cardDescription='Taladro atornillador - 16v - dos baterias de litio.'
                                cardPrice='$ 4.160'
                                cardImage={toolProduct9}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Taladro Atornillador'
                                cardDescription='Taladro atornillador - 12v - una bateria de litio.'
                                cardPrice='$ 2.145'
                                cardImage={toolProduct10}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Taladro Atornillador'
                                cardDescription='Taladro atornillador - 12v - dos baterias de litio.'
                                cardPrice='$ 2.587'
                                cardImage={toolProduct10}
                                productSize='N/A'
                                productColor='N/A'
                            />

                            <ProductCard 
                                cardTitle='Sierra Circular'
                                cardDescription='Sierra Circular - 20v - 4Amp - dos baterias de litio.'
                                cardPrice='$ 7.280'
                                cardImage={toolProduct11}
                                productSize='N/A'
                                productColor='N/A'
                            />
                        </div>
                    </div>
                </div>
            </div>
            <WhatsappButton />
            <FooterWave />
            <Footer />
        </>
    );
};

// Export PreHouse Home Component
export default PreHouseHome;