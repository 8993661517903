// Import React Library
import React, {useState} from 'react';

// Import Images
import logoPreHouse from '../assets/images/logo-pre-house-2.png'

// Import Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown , faHouse, faPersonDigging } from '@fortawesome/free-solid-svg-icons';
import SensorWindowIcon from '@mui/icons-material/SensorWindow';
import VillaIcon from '@mui/icons-material/Villa';
import MenuIcon from '@mui/icons-material/Menu';
import CabinIcon from '@mui/icons-material/Cabin';
import CloseIcon from '@mui/icons-material/Close';


// Import Link react-router
import { Link } from 'react-router-dom';

// Mobile Navbar Component
const NavbarMobile = ({status}) => {
    const [openMobileMenu , setOpenMobileMenu] = useState(false);

    return (
        <>
            <nav className='nav-mobile'>
                <Link to='/'>
                    <img src={logoPreHouse} alt='Logo PreHouse'/>
                </Link>
                
                <MenuIcon  className='menu-icon' onClick={() => setOpenMobileMenu(true)}/>
            </nav>

            {
                openMobileMenu ? 
                <ul className='mobile-ul'>
                    <CloseIcon className='close-icon' onClick={() => setOpenMobileMenu(false)}/>
                    <Link to='/galvanized-iron'>
                        <li>Hierros  <CabinIcon className='general-icon'/></li>
                    </Link>
                    <Link to='/windows'>
                        <li>Aberturas  <SensorWindowIcon className='general-icon'/></li>
                    </Link>
                    <Link to='/isopaneles'>
                        <li>Isopaneles <VillaIcon className='general-icon'/></li>
                    </Link>
                    <Link to='/constructions'>
                        <li>Construcciónes <FontAwesomeIcon icon={faPersonDigging} className='general-icon'/></li>
                    </Link>
                    <Link to='/pre-house-home'>
                        <li>PreHouse Home <FontAwesomeIcon icon={faHouse} className='general-icon'/></li>
                    </Link>

                    { status ? <li className='contact-link'><a href='#contact-form'>Contacto</a></li> : ''}
                </ul> : ''
            }
        </>
    );
};

// Export Mobile Nabvar Component
export default NavbarMobile;